import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';


@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {
 
  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
    this.setupAppListeners();
  }

  private setupAppListeners() {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        // App is in the foreground
        //this.checkForResetCondition();
        
        console.log('App is in the foreground');
        // Perform actions or check conditions to decide if a reset is needed
       
      } else {
      
        this.checkForResetCondition();
        // App is in the background
        console.log('App is in the background');
      }
    });
  }

  private checkForResetCondition() {
    // Implement your logic to check if a reset is needed
    const resetNeeded = true; // Replace with your condition

    if (resetNeeded) {
      console.log('Resetting the app...');
      // Perform actions to reset the app, this might include resetting state, reloading data, etc.
      this.resetApp();

      //window.location.href="index.html";
    }
  }

  private resetApp() {
    // Implement your logic to reset the app
    // This might include navigating to a specific page, reloading data, etc.
    // For example, you can use Angular Router to navigate to the initial route
    console.log('in resetApp');
    //window.location.href="index.html";
    //this._router.navigate(['/']); test comment
  }

}
