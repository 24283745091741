<style>
#navbar {
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    transition: all 0.4s linear 0s;
    z-index: 999;
    position: relative;
    background: #e1e1e1;
}
html.small #navbar {
    width: 100%;
    position: fixed;
    visibility: visible !important;
    padding: 0;
    top: 0;
    left: 0;
    -webkit-animation: slide-down .7s;
    -moz-animation: slide-down .7s;
    -ms-animation: slide-down .7s;
    -o-animation: slide-down .7s;
    animation: slide-down .7s;
    z-index: 9999;
}
.navbar-collapse                { float: right; padding: 0;}

</style>

<app-loader *ngIf="loading"></app-loader>
<!-- Navigation section  -->
<div class="navbar navbar-default" role="navigation">
  <div class="container">
      <div class="navbar-header wow fadeInLeft" data-wow-duration="1.4s" data-wow-delay="0.3s">
          <button class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> 
                  <span class="icon icon-bar chnge1"></span> 
                  <span class="icon icon-bar dis_non"></span> 
                  <span class="icon icon-bar chnge2"></span> 
          </button>
          <a href="index.html" class="navbar-brand smoothScroll"><img src="../../../assets/images/aayumeet-logo.png" alt="" /></a>
      </div>
      <div class="collapse navbar-collapse wow fadeInRight" data-wow-duration="1.4s" data-wow-delay="0.3s">
          <ul class="nav navbar-nav navbar-right">
              <li><a href="#home" class="smoothScroll"><span>Home</span></a></li>
              <li><a href="#who_we_are" class="smoothScroll"><span>About Us</span></a></li>
              <li><a class="smoothScroll"><span>Our Services</span></a>
                  <ul>
                      <li><a [routerLink]="['/digitalization']">Digitalization</a></li>
                      <li><a [routerLink]="['/features']">App features</a></li>
                  </ul>
              </li>
              <li><a class="active" [routerLink]="['/packages']"><span>Our Packages</span></a></li>
              
              <li><a href="#faq_sect" class="smoothScroll"><span>FAQ's</span></a></li>
              <li><a href="#section_6" class="smoothScroll"><span>Contact Us</span></a></li>
            <li><a href="https://health.aayumeet.in/login" class="signin dis1" target="_blank">Sign In</a></li>
          </ul>
          <div class="clear"></div>
          
      </div>
    <a href="https://health.ulternal.com/login" class="signin dis1_non" target="_blank">Sign In</a>
  </div>
</div>