import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// import { ChangePassDialogboxComponent } from '../../../pages/clinic/views/changePassdialogbox/changePassdialogbox.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  encapsulation: ViewEncapsulation.None, // disable ViewEncapsulation
})

export class NavbarComponent implements OnInit {
  constructor(
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
  ) { }
  public navMenus = [];
  public mNavs = [];
  public activeNav = 0;

  public desktopNavs = [];
  public desktopNavs1 = [];
  public loggedUser = '';
  public loggedRole = 0;
  public loading = false;
  public activeLink = '';
  public userAuthToken = null;

  ngOnInit(): void { //this.loading = true;
    this.activeLink = this._aRoute.snapshot['_routerState']['url'].replace('/', '');
    console.log(this.activeLink);
    if(localStorage.getItem('loggedUser')) {
      let lUser = JSON.parse(localStorage.getItem('loggedUser'));
      this.userAuthToken = JSON.parse(localStorage.getItem('token'));

      this.loggedUser = lUser.FirstName;

     
    }
  }


  navFunc(navObj) { 
   
    localStorage.setItem("E_DiscountId", null);
    localStorage.setItem("E_UserId", null);
    localStorage.setItem('isrefreshed', null);

    this._router.navigate([navObj]);   
  }

  changePassword() {
    
  }
}
