import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `<div class="loaderContainer">
    <mat-spinner></mat-spinner>
  </div>`,
  styles: [`
    .loaderContainer{
      width: 100%; height: 100%;
      position: fixed; z-index: 5000;
      top: 0px; left: 0px;
      background: rgba(0, 0, 0, 0.75);
      display: flex; align-items: center;
      justify-content: center;
    }
  `],
})

export class LoaderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}