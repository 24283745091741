import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppRoutingModule, AppRoutes } from './app-routing.module';
import { MaterialModule } from './material.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { AppComponent } from './app.component';
// import { FollowupDialogboxComponent } from './components/followupdialogbox/followupdialogbox.component';
// import { RWnAPDialogboxComponent } from './components/rwnapDialogbox/rwnapDialogbox.component';

import {MatTabsModule} from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';

import { CommonCompModule } from './components/commonComp/commonComp.module';
import { MainpageComponent } from './pages/view/mainpage/mainpage.component';

@NgModule({
    declarations: [
        AppComponent, ...AppRoutes, MainpageComponent
        // AssistedDialogboxComponent,
        // RWnAPDialogboxComponent,
        //...MainRoutes,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatExpansionModule,
        MatDividerModule,
        // FollowupDialogboxComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        CommonCompModule,
        NgCircleProgressModule.forRoot({
            "outerStrokeLinecap": "butt"
        }),
        AppRoutingModule,
        MatTabsModule,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL'],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
