import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainpageComponent } from './pages/view/mainpage/mainpage.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'mainpage' },
  { path: 'mainpage', component: MainpageComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
}
export const AppRoutes = [
  MainpageComponent

]
