import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../../material.module';
import { LoaderComponent } from './loader/loader.component';
import { NavbarComponent } from './navbar/navbar.component';
// import { ChangePassDialogboxComponent } from '../../pages/clinic/views/changePassdialogbox/changePassdialogbox.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
    declarations: [LoaderComponent, NavbarComponent,],
    imports: [
        CommonModule, BrowserAnimationsModule,
        RouterModule, MaterialModule, BrowserModule,
        FormsModule, ReactiveFormsModule,
    ],
    exports: [LoaderComponent, NavbarComponent],
    // entryComponents: [ChangePassDialogboxComponent,]
})
export class CommonCompModule { }
